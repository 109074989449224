/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useCallback, useEffect, useRef } from 'react';
import reactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import './Modal.css';

interface Props {
  children: JSX.Element;
  isOpen: boolean;
  close: () => void;
  side?: string;
  size?: 'small';
  openBtn: HTMLButtonElement | null;
  closeCallback: React.RefObject<{ f: () => void }>;
  keepMounted?: boolean;
  preventNavigate?: boolean;
}

function Modal({
  children,
  isOpen,
  close,
  side = 'center',
  openBtn,
  closeCallback,
  keepMounted,
  preventNavigate,
  size,
}: Props) {
  const navigate = useNavigate();
  const nodeRef = useRef<HTMLDivElement>(null);
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const fromPop = useRef<boolean>(false);
  const initialized = useRef(false);

  useEffect(() => {
    if (!isOpen) return;

    initialized.current = true;
    fromPop.current = false;
    window.history.pushState({}, '');
    const handlePopstate = () => {
      fromPop.current = true;
      close();
    };
    window.addEventListener('popstate', handlePopstate);

    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handlePopstate);
      }, 0);
    };
  }, [close, isOpen]);

  const handleModalEnter = useCallback(() => {
    const header = document.querySelector('.header') as HTMLDivElement;
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = 'clip';
    document.body.style.inset = `${document.body.getBoundingClientRect().top}px 0 0 0`;
    document.body.style.insetInlineEnd = `${scrollbarWidth}px`;
    document.body.style.position = 'fixed';
    if (header) {
      header.style.width = `calc(100% - ${scrollbarWidth}px)`;
    }
  }, []);

  const handleModalClosed = useCallback(() => {
    const header = document.querySelector('.header') as HTMLDivElement;

    const y = document.body.getBoundingClientRect().top;
    document.body.style.inset = '';
    document.body.style.overflow = '';
    document.body.style.insetInlineEnd = '';
    document.body.style.position = '';
    window.scrollTo(0, -y);
    if (header) {
      header.style.width = '';
    }
    if (openBtn) {
      setTimeout(() => {
        openBtn.focus({
          preventScroll: true,
        });
      }, 0);
    }
    if (closeCallback.current) closeCallback.current.f();
  }, [openBtn, closeCallback]);

  const handleModalExiting = useCallback(() => {
    if (!fromPop.current && !preventNavigate) {
      navigate(-1);
    }
  }, [preventNavigate, navigate]);

  const handleKeyDown = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (!nodeRef.current) return;
      const elements = [
        ...nodeRef.current.querySelectorAll(
          'a[href], button, input:not([type="hidden"]), textarea, select, details, [tabindex]:not([tabindex="-1"]), iframe:not([style="display: none;"])',
        ),
      ].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
      const { length } = elements;

      if (length === 2) e.preventDefault();

      if (!e.shiftKey && e.keyCode === 9) {
        if (
          e.target === elements[length - 2] ||
          e.target === elements[length - 1] ||
          // Check if it is a dropdown item
          e.target.parentNode.previousSibling === elements[length - 2]
        ) {
          e.preventDefault();
          setTimeout(() => {
            (elements[1] as HTMLElement).focus();
          }, 0);
        }
      }

      if (e.shiftKey && e.keyCode === 9) {
        if (e.target === elements[1] || e.target === elements[0]) {
          (elements[length - 1] as HTMLElement).focus();
        }
      }

      if (e.keyCode === 27) close();
    },
    [close],
  );

  useEffect(() => {
    const elem = nodeRef.current;
    if (!elem) return;

    setTimeout(() => {
      if (!elem) return;
      [
        ...elem.querySelectorAll<HTMLButtonElement>(
          'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
        ),
      ]
        .filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'))[1]
        .focus();
    }, 300);

    if (isOpen) {
      elem?.addEventListener('keydown', handleKeyDown);
    } else {
      elem?.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      elem?.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, handleKeyDown, nodeRef]);

  if (!modalContainer) return null;

  return reactDOM.createPortal(
    <CSSTransition
      nodeRef={nodeRef}
      in={isOpen}
      timeout={300}
      classNames="modal"
      onExited={handleModalClosed}
      onExiting={handleModalExiting}
      onEnter={handleModalEnter}
      unmountOnExit={!(keepMounted && initialized.current)}
    >
      <div
        className={`modal ${side}${keepMounted && !isOpen ? ' keep-mounted-closed' : ''}${
          size ? ` ${size}` : ''
        }`}
        ref={nodeRef}
        onClick={close}
        role="presentation"
      >
        <div className="modal__overflow" onClick={(e) => e.stopPropagation()} role="presentation">
          <OutsideAlerter
            onClick={() => {
              if (!isOpen) return;
              close();
            }}
          >
            <span tabIndex={0} />
            <div className="modal__container" role="dialog">
              {children}
            </div>
            <span tabIndex={0} />
          </OutsideAlerter>
        </div>
      </div>
    </CSSTransition>,
    modalContainer,
  );
}

export default Modal;
