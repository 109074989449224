import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import senitize from 'rehype-sanitize';

import CustomLink from '../../common/CustomLink/CustomLink';
import { ILink, TFontSize } from '../../core/models';
import './Introduction.css';

interface IProps {
  title: string;
  size?: TFontSize;
  desc?: string;
  descSize?: TFontSize;
  cta?: ILink;
  background?: 'red' | 'white' | 'dark-blue' | 'light-blue';
}

function Introduction({ title, size, desc, descSize, cta, background }: IProps) {
  return (
    <section
      className={`introduction section-bg${
        !background || background === 'red'
          ? ' section-bg--secondary'
          : background === 'white'
          ? ' section-bg--white'
          : background === 'dark-blue'
          ? ' section-bg--dark'
          : background === 'light-blue'
          ? ' section-bg--primary'
          : ''
      }`}
    >
      <div className="wrap">
        <div className="introduction__container gap-lg">
          <div className="introduction__left">
            <h2 className={`introduction__title ${size || 'h2'}`}>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw, senitize]}
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h2>
          </div>
          <div className="introduction__right gap-md">
            {!desc ? null : (
              <ReactMarkdown
                className={`introduction__desc ${descSize || 'h3'}`}
                rehypePlugins={[rehypeRaw, senitize]}
              >
                {desc}
              </ReactMarkdown>
            )}
            {!cta ? null : <CustomLink className="introduction__cta" link={cta} />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Introduction;
