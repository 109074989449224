/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useCallback, useMemo, useRef } from 'react';

import AIFeedback from '../../Components/AIFeedback/AIFeedback';
import CustomButton from '../../common/CustomButton/CustomButton';
import { Icon } from '../../common/Icon/Icon';
import { slugify } from '../helpers';
import { getCookie } from '../service';
import useModal from './useModal';

interface IOptions {
  ai: any;
  btnRef: RefObject<HTMLButtonElement>;
}

const FEEDBACK_THRESHOLD = 60;

const useFeedback = ({ ai, btnRef }: IOptions) => {
  const feedbackCookie = getCookie(
    `feedback-${slugify(ai?.appId || ai?.assistantID || ai?.title || '')}`,
  );
  const { modal: feedbackModal, open: feedbackOpen, close: feedbackClose } = useModal();
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const timerValue = useRef(0);
  const clearTimer = () => {
    timerValue.current = 0;
    if (!timer.current) return;
    clearInterval(timer.current);
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      timerValue.current += 1;
    }, 1000);
  };

  const closeModal = useCallback(() => {
    if (ai?.feedback?.type && timerValue.current >= FEEDBACK_THRESHOLD && !feedbackCookie) {
      setTimeout(() => {
        if (!btnRef.current) return;
        feedbackOpen(btnRef.current);
      }, 400);
    }
    clearTimer();
  }, [ai?.feedback?.type, feedbackCookie, btnRef, feedbackOpen]);

  const Modal = useCallback(
    () =>
      !ai?.feedback?.type
        ? null
        : feedbackModal({
            side: 'center',
            size: 'small',
            children: (
              <>
                <CustomButton
                  className="modal__close"
                  onClick={feedbackClose}
                  type="button"
                  id={`${slugify(
                    ai.appId || ai.assistantID || ai.title || '',
                  )}-feedback-modal-close`}
                >
                  <Icon.Plus className="icon-large" />
                  <span className="sr-only">Close modal</span>
                </CustomButton>
                <AIFeedback ai={ai} callback={feedbackClose} />
              </>
            ),
          }),
    [feedbackClose, feedbackModal, ai],
  );

  const value = useMemo(
    () => ({
      startTimer,
      closeModal,
      feedbackCookie,
      Modal,
    }),
    [Modal, closeModal, feedbackCookie],
  );

  return value;
};

export default useFeedback;
