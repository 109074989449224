import React, { useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import { slugify } from '../../core/helpers';
import useFeedback from '../../core/hooks/useFeedback';
import useModal from '../../core/hooks/useModal';
import { ILink } from '../../core/models';
import Chat, { IOpenAI } from './Chat/Chat';
import './OpenAI.css';

interface IProps {
  title: string;
  desc?: string;
  cta?: ILink;
  openai?: IOpenAI;
  background?: 'red' | 'white' | 'dark-blue' | 'light-blue';
}

function OpenAI({ title, desc, cta, openai, background }: IProps) {
  const btnRef = useRef(null);
  const {
    closeModal,
    startTimer,
    feedbackCookie,
    Modal: FeedbackModal,
  } = useFeedback({ ai: openai, btnRef });
  const { modal, open, close } = useModal({ closeCallback: closeModal });

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
    if (!feedbackCookie) startTimer();
  }, [feedbackCookie, open, startTimer]);

  return (
    <section
      className={`openai section-bg${
        !background || background === 'red'
          ? ' section-bg--secondary'
          : background === 'white'
          ? ' section-bg--white'
          : background === 'dark-blue'
          ? ' section-bg--dark'
          : background === 'light-blue'
          ? ' section-bg--primary'
          : ''
      }`}
    >
      <div className="wrap">
        <div className="openai__container gap-lg">
          <div className="openai__left">
            <h2 className="openai__title h2">
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h2>
          </div>
          <div className="openai__right gap-md">
            {!desc ? null : <ReactMarkdown className="openai__desc h3">{desc}</ReactMarkdown>}
            {!cta && !openai?.assistantID ? null : (
              <div className="openai__btn-container">
                {!cta ? null : <CustomLink className="openai__cta" link={cta} />}
                {!openai?.assistantID ? null : (
                  <CustomButton
                    className={`btn btn--${background === 'light-blue' ? 'secondary' : 'primary'}`}
                    onClick={openModal}
                    type="button"
                    ref={btnRef}
                    id={openai?.btnId}
                  >
                    {openai.btnLabel || 'Open'}
                  </CustomButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!openai?.assistantID
        ? null
        : modal({
            side: 'right',
            keepMounted: true,
            children: (
              <>
                <CustomButton
                  className="modal__close"
                  onClick={close}
                  type="button"
                  id={`${slugify(title)}-modal-close`}
                >
                  <Icon.Plus className="icon-large" />
                  <span className="sr-only">Close modal</span>
                </CustomButton>
                {!openai?.assistantID ? null : <Chat ai={openai} />}
              </>
            ),
          })}
      <FeedbackModal />
    </section>
  );
}

export default OpenAI;
