/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import CustomLink from '../../common/CustomLink/CustomLink';
import { slugify } from '../../core/helpers';
import { setCookie } from '../../core/service';
import './AIFeedback.css';

interface IProps {
  ai: any;
  callback?: () => unknown;
}

const COOKIE_EXP = 30;

function AIFeedback({ ai, callback }: IProps) {
  const handleClick = useCallback(() => {
    setCookie(
      `feedback-${slugify(ai.appId || ai.assistantID || ai.title || '')}`,
      'true',
      COOKIE_EXP,
    );
    if (callback) callback();
  }, [callback, ai]);

  return (
    <div className="feedback">
      <h2 className="feedback__title h3">{ai.feedback?.title}</h2>
      {ai.feedback?.type === 'external' ? (
        !ai.feedback.cta ? null : (
          <CustomLink className="feedback__cta" link={ai.feedback.cta} onClick={handleClick} />
        )
      ) : (
        <div>Not yet implemented. Please use the external type</div>
      )}
    </div>
  );
}

export default AIFeedback;
