import { createContext, useContext, useLayoutEffect, useState } from 'react';

import { BREAKPOINTS } from '../constants';
import { debounce } from '../helpers';

interface IScreenSize {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
}

interface IProps {
  children: JSX.Element;
}

const ScreenSizeContext = createContext<IScreenSize | null>(null);

export const useScreenSizeContext = () => useContext(ScreenSizeContext) as IScreenSize;

export function ScreenSizeContextProvider({ children }: IProps) {
  const [size, setSize] = useState<IScreenSize>({
    width: 0,
    height: 0,
    isMobile: window.innerWidth < BREAKPOINTS.mobile,
    isTablet: window.innerWidth < BREAKPOINTS.tablet && window.innerWidth >= BREAKPOINTS.mobile,
  });

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      setSize({
        width: window.visualViewport ? window.visualViewport.width : window.innerWidth,
        height: window.visualViewport ? window.visualViewport.height : window.innerHeight,
        isMobile: window.innerWidth < BREAKPOINTS.mobile,
        isTablet: window.innerWidth < BREAKPOINTS.tablet && window.innerWidth >= BREAKPOINTS.mobile,
      });
    }, 100);
    handleResize();

    window.addEventListener('resize', handleResize);
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (size.width > 0) {
      document.documentElement.style.setProperty('--vw', `${(size.width * 0.01).toFixed(2)}px`);
      document.documentElement.style.setProperty('--vh', `${(size.height * 0.01).toFixed(2)}px`);
    }
  }, [size]);

  return <ScreenSizeContext.Provider value={size}>{children}</ScreenSizeContext.Provider>;
}
