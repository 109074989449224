/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-promise-executor-return */

/* eslint-disable no-restricted-syntax */
import OpenAI from 'openai';
import { TextContentBlock } from 'openai/resources/beta/threads/messages';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';
import Spinner from '../../../common/Spinner/Spinner';
import { useScreenSizeContext } from '../../../core/context/screenSize.context';
import { isEmpty, isJsonString } from '../../../core/helpers';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { ILink, TColor } from '../../../core/models';
import { getCookie, setCookie } from '../../../core/service';
import './Chat.css';

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

interface IProps {
  ai: IOpenAI;
}

interface IMessage {
  id: string;
  text: string;
  role: 'user' | 'assistant';
  created_at: string;
}

interface ICookie {
  id: string;
  label: string;
}

export interface IOpenAI {
  title?: string;
  assistantID?: string;
  btnLabel?: string;
  btnId?: string;
  initialMsg?: string;
  feedback?: {
    type?: 'external' | 'scale';
    title?: string;
    cta?: ILink;
    btnText?: string;
    btnColor?: TColor;
  };
}

function Chat({ ai }: IProps) {
  const { isMobile } = useScreenSizeContext();
  const input = useFormInput('', (value) => !isEmpty(value));
  const [openAI, setOpenAI] = useState<OpenAI | null>(null);
  const [threads, setThreads] = useState<ICookie[]>([]);
  const [thread, setThread] = useState<string | null>(null);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [threadLoading, setThreadLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [showThreads, setShowThreads] = useState(false);
  const chatMessagesRef = useRef<HTMLDivElement | null>(null);

  const updateLabel = useCallback(
    (label: string, id: string) => {
      const cookieThreads = getCookie(`openAIThread-${ai.assistantID}`);
      if (!isJsonString(cookieThreads)) return;

      const cookieThreadsArr: ICookie[] = JSON.parse(cookieThreads);
      const newCookieThreadsArr: ICookie[] = cookieThreadsArr.map((el) =>
        el.id === id ? { ...el, label } : el,
      );
      setCookie(`openAIThread-${ai.assistantID}`, JSON.stringify(newCookieThreadsArr));
      setThreads(newCookieThreadsArr);
    },
    [ai.assistantID],
  );

  const printMessages = useCallback(
    async (threadId: string, AI: OpenAI) => {
      if (!AI) return;
      const newMessages:
        | OpenAI.Beta.Threads.Messages.MessagesPage
        | OpenAI.Beta.Threads.Messages.Message = await AI.beta.threads.messages.list(threadId);
      setThreadLoading(false);
      if (newMessages.data.at(-1)?.role === 'user') newMessages.data.pop();
      setMessages(() =>
        newMessages.data.map((message) => ({
          id: message.id,
          text: (message.content[0] as TextContentBlock).text.value,
          role: message.role,
          created_at: new Date(message.created_at * 1000).toLocaleString(),
        })),
      );
      updateLabel(
        (newMessages.data[0]?.content[0] as TextContentBlock)?.text.value || 'New thread',
        threadId,
      );
    },
    [updateLabel],
  );

  const printMessageChunk = useCallback(
    (event: any) => {
      switch (event.event) {
        case 'thread.message.created':
          setMessages((prevState) => [
            {
              id: event.data.id,
              text: '',
              role: 'assistant',
              created_at: new Date(event.data.created_at * 1000).toLocaleString(),
            },
            ...prevState,
          ]);
          break;
        case 'thread.message.delta':
          setMessages((prevState) => {
            const newMessages = prevState.map((message) => ({ ...message }));
            const msgIdx = newMessages.findIndex((message) => message.id === event.data.id);
            if (msgIdx === -1) return newMessages;
            if (!newMessages[msgIdx]?.text) newMessages[msgIdx].text = '';
            newMessages[msgIdx].text += event.data.delta.content[0]?.text.value || '';
            return newMessages;
          });
          break;
        case 'thread.message.completed':
          updateLabel(event.data.content[0].text.value, event.data.thread_id);
          break;
        default:
          break;
      }
    },
    [updateLabel],
  );

  useEffect(() => {
    if (!thread || !openAI) return;
    printMessages(thread, openAI);
  }, [thread, openAI, printMessages]);

  const submit = useCallback(
    async (message?: string, threadId?: string) => {
      if (!openAI || (!thread && !threadId) || !ai.assistantID) return;
      if (isMobile) {
        input.ref?.current?.blur();
      }
      setChatLoading(true);
      if (chatMessagesRef?.current) {
        chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      }
      const newMessage = await openAI.beta.threads.messages.create(threadId || thread || '', {
        role: 'user',
        content: message || input.value,
      });
      input.setValue('');
      if (!message) {
        setMessages((prevState) => [
          {
            id: newMessage.id,
            text: (newMessage.content[0] as any)?.text.value,
            role: 'user',
            created_at: new Date(newMessage.created_at * 1000).toLocaleString(),
          },
          ...prevState,
        ]);
      }

      try {
        const stream = await openAI.beta.threads.runs.create(threadId || thread || '', {
          assistant_id: ai.assistantID,
          stream: true,
        });
        for await (const event of stream) {
          printMessageChunk(event);
          await new Promise((resolve) => setTimeout(resolve, 50));
        }
        setChatLoading(false);
      } catch (error) {
        setChatLoading(false);
      }
    },
    [ai.assistantID, printMessageChunk, thread, isMobile, openAI, input.value],
  );

  const createNewThread = useCallback(
    async (newOpenAI: OpenAI, prevThreads: { id: string; label: string }[]) => {
      setThreadLoading(true);
      setChatLoading(true);
      setMessages([]);
      const newThread: OpenAI.Beta.Threads.Thread = await newOpenAI.beta.threads.create();
      const newThreadObj = { id: newThread.id, label: 'New thread' };
      setCookie(`openAIThread-${ai.assistantID}`, JSON.stringify([...prevThreads, newThreadObj]));
      setThreads(() => [...prevThreads, newThreadObj]);
      setThread(newThread.id);
      setThreadLoading(false);
      if (isMobile) {
        setShowThreads(false);
      }
      if (!ai.initialMsg) return;
      submit(ai.initialMsg, newThread.id);
    },
    [ai.assistantID, ai.initialMsg, isMobile, submit],
  );

  useEffect(() => {
    if (!ai.assistantID || !apiKey || thread) return;

    const newOpenAI: OpenAI = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true,
    });
    setOpenAI(newOpenAI);

    const initThread = () => {
      const cookieThreads = getCookie(`openAIThread-${ai.assistantID}`);
      if (!isJsonString(cookieThreads)) {
        createNewThread(newOpenAI, []);
      } else {
        const cookieThreadsArr: ICookie[] = JSON.parse(cookieThreads);
        if (cookieThreadsArr.length) {
          setThreads(cookieThreadsArr);
          setThread(cookieThreadsArr[cookieThreadsArr.length - 1].id);
          // setThreadLoading(true);
          printMessages(cookieThreadsArr[cookieThreadsArr.length - 1].id, newOpenAI);
        } else {
          createNewThread(newOpenAI, []);
        }
      }
    };
    initThread();
  }, [ai.assistantID]);

  const deleteThread = useCallback(
    (id: string) => {
      if (!openAI) return;
      setThreads((prevState) => {
        const filteredThreads = prevState.filter((el) => el.id !== id);
        setThread(filteredThreads[0]?.id || null);
        setCookie(`openAIThread-${ai.assistantID}`, JSON.stringify(filteredThreads));
        return filteredThreads;
      });
    },
    [ai.assistantID, openAI],
  );

  useEffect(() => {
    if (threads.length || !openAI) return;
    createNewThread(openAI, []);
  }, [threads, createNewThread, openAI]);

  return (
    <div className={`chat${threadLoading ? ' loading' : ''}`}>
      {!openAI || !ai.assistantID ? null : (
        <div className="chat__container">
          <div className={`chat__content${showThreads ? ' open' : ''}`}>
            <div className="chat__chat">
              <div className="chat__header">
                {!ai.title ? null : <h2 className="h3">{ai.title}</h2>}
                <CustomButton
                  className="chat__thread-toggle btn btn--ghost"
                  type="button"
                  onClick={() => setShowThreads(!showThreads)}
                >
                  <Icon.Chat />
                  Chats ({threads.length})
                  <span className="sr-only">{showThreads ? 'hide threads' : 'show threads'}</span>
                </CustomButton>
              </div>
              <div className="chat__messages" ref={chatMessagesRef}>
                {messages?.map((message) => (
                  <div
                    className={`chat__message-container${
                      message.role === 'user' ? ' chat__message-container--user' : ''
                    }`}
                    key={message.id}
                  >
                    <ReactMarkdown className="chat__message body-regular markdown">
                      {message.text}
                    </ReactMarkdown>
                    {/* <span className="chat__message-date">{message.created_at}</span> */}
                  </div>
                ))}
              </div>
              <CustomForm className="chat__form" inputs={[input]} onSubmit={submit}>
                <div className="chat__input-container">
                  <CustomInput
                    className="chat__input"
                    input={input}
                    id={`chat-${ai.assistantID}`}
                    type="text"
                    name="chat"
                    placeholder="Type message"
                    autoComplete="off"
                  />
                  <CustomButton
                    className="chat__btn chat-btn"
                    type="submit"
                    disabled={chatLoading || threadLoading}
                  >
                    {chatLoading ? <Spinner /> : <Icon.Arrow />}
                  </CustomButton>
                </div>
              </CustomForm>
            </div>
            <div className="chat__threads">
              {!isMobile ? null : (
                <CustomButton
                  className="chat__thread-back btn btn--ghost"
                  type="button"
                  onClick={() => setShowThreads(false)}
                  disabled={!showThreads}
                >
                  <Icon.Arrow className="rotate-minus-90" />
                  Back to chat
                </CustomButton>
              )}
              <div className="chat__threads-title body-small">Recent chats</div>
              {threads.map((el) => (
                <div className="chat__thread-container" key={el.id}>
                  <CustomButton
                    className={`chat__thread${el.id === thread ? ' active' : ''}`}
                    type="button"
                    onClick={() => {
                      if (isMobile) {
                        setShowThreads(false);
                      }
                      if (el.id === thread) return;
                      setThreadLoading(true);
                      setThread(el.id);
                    }}
                    disabled={!showThreads || chatLoading}
                  >
                    <ReactMarkdown className="truncate l-1">{el.label}</ReactMarkdown>
                  </CustomButton>
                  <CustomButton
                    className="chat__thread-delete"
                    type="button"
                    onClick={() => deleteThread(el.id)}
                    disabled={!showThreads}
                  >
                    <Icon.Bin />
                    <span className="sr-only">delete thread {el.label}</span>
                  </CustomButton>
                </div>
              ))}
              <CustomButton
                className="chat__new btn btn--dark"
                type="button"
                onClick={() => createNewThread(openAI, threads)}
                disabled={!showThreads || threadLoading || chatLoading}
              >
                New chat
                <Icon.Plus />
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;
